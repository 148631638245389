import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slide", "dot", "link"];
  static values = { index: Number };

  connect() {
    this.showSlide(this.indexValue);
  }

  first() {
    this.indexValue = 0;
    this.showSlide(this.indexValue);
  }

  second() {
    this.indexValue = 1;
    this.showSlide(this.indexValue);
  }

  next() {
    this.indexValue = (this.indexValue + 1) % this.slideTargets.length;
    this.showSlide(this.indexValue)
  }

  previous() {
    this.indexValue = (this.indexValue - 1 + this.slideTargets.length) % this.slideTargets.length;
    this.showSlide(this.indexValue)
  }

  showSlide(index) {
    const offset = -index * 100;
    this.slideTargets.forEach((el, i) => {
      el.style.transform = `translateX(${offset}%)`;
    });

    this.dotTargets.forEach((dot, i) => {
      if (i === index) {
        dot.classList.add("view-selected");
        dot.classList.remove("view-unselected");
      } else {
        dot.classList.add("view-unselected");
        dot.classList.remove("view-selected");
      }
    });

    // if we changed the view with JavaScript, we need to update URL params as well for backend
    this.linkTargets.forEach((link, i) => {
      const url = new URL(link.href);
      url.searchParams.set('view', index === 1 ? "dash" : "seat");
      link.href = url.toString();
    });
  }
}
